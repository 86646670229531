<template>
  <div class="offer_analysis_rule">
    <el-tabs v-model="activeName" @tab-click="callback">
      <el-tab-pane
        size="small"
        :name="$t('trade.text13')"
        :label="$t('trade.text13')"
      >
        <div class="offer">
          <div class="myCanvas">
            <canvas id="myCanvas" height="517"></canvas>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :name="$t('trade.text14')" :label="$t('trade.text14')">
        <div class="offer rule">
          <div class="rule-content">
            <ul class="rule-content-box">
              <li
                class="rule-item"
                v-for="ruleItem in rules.list"
                :key="ruleItem.key"
              >
                <p class="rule-title">{{ ruleItem.key }}</p>
                <p class="rule-context">{{ ruleItem.val }}</p>
              </li>
            </ul>
            <div class="rule-times">
              <h3 class="offer-title analysis-title">
                {{ $t("trade.text16") }}
              </h3>
              <div class="rule-time-table">
                <div class="rule-time-table-header rule-time-table-row">
                  <p class="rule-time-col">{{ $t("trade.text17") }}</p>
                  <p class="rule-time-col">{{ $t("trade.text18") }}</p>
                  <p class="rule-time-col">{{ $t("trade.text19") }}</p>
                </div>
                <div
                  class="rule-time-table-row"
                  v-for="timeItem in rules.time"
                  :key="timeItem.day"
                >
                  <p class="rule-time-col">{{ timeItem.day }}</p>
                  <div class="rule-time-col">
                    <p
                      v-for="times in timeItem.quote"
                      :key="timeItem.day + times.direction"
                    >
                      {{ times.startime }}-{{ times.endtime }}
                    </p>
                  </div>
                  <div class="rule-time-col">
                    <p
                      v-for="times in timeItem.trade"
                      :key="timeItem.day + times.direction"
                    >
                      {{ times.startime }}-{{ times.endtime }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :name="$t('trade.text15')"
        :label="$t('trade.text15')"
        force-render
      >
        <div class="offer">
          <div class="short-empty-chart">
            <h3 class="offer-title">{{ $t("trade.text20") }}</h3>
            <div class="short-chart">
              <div
                class="chart-item justify-content-between"
                v-for="item in shortList"
                :key="item.platform"
              >
                <div class="short-data">
                  <p class="left down">
                    {{ $t("trade.text21") }}{{ item._short }}%
                  </p>
                  <p class="center platform">{{ item.platform }}</p>
                  <p class="right up">
                    {{ $t("trade.text22") }}{{ item._long }}%
                  </p>
                </div>
                <p class="short-chart-box">
                  <span
                    class="bgdown down-line"
                    :style="{ width: item._short + '%' }"
                  ></span>
                  <span
                    class="bgup up-line"
                    :style="{ width: item._long + '%' }"
                  ></span>
                </p>
              </div>
              <div class="long_empty" v-if="shortList.length <= 0">
                <div class="empty_line"></div>
                <div class="empty_line"></div>
                <div class="empty_line"></div>
                <div class="empty_line"></div>
              </div>
            </div>
            <div class="interpretation-box">
              <div class="offer-title">
                {{ $t("trade.text23")
                }}<i
                  class="iconfont iconicon_instructions"
                  @mouseover="popconfirmFlag = true"
                  @mouseleave="popconfirmFlag = false"
                ></i>
                <div
                  class="popconfirm"
                  v-show="popconfirmFlag"
                  @mouseover="popconfirmFlag = true"
                  @mouseleave="popconfirmFlag = false"
                >
                  <div class="popconfirm-title">总结:</div>
                  <div class="popconfirm-text">
                    综合28个指标的信号，输出后市的走势方向。28个信号输出越一致，方向越显著。
                  </div>
                  <div class="popconfirm-title">震荡指标:</div>
                  <div class="popconfirm-text">
                    囊括10个震荡行情指标的综合指标，适合日内交易者参考。
                  </div>
                  <div class="popconfirm-title">枢轴点:</div>
                  <div class="popconfirm-text">
                    判断阻力和支撑位的指标体系，S代表支撑，R代表阻力。
                  </div>
                  <el-button type="primary" class="popconfirm-btn"
                    >了解更多</el-button
                  >
                </div>
              </div>
              <el-tabs
                size="small"
                class="interpretation-tab"
                v-model="activTab"
                tabPosition="top"
                @tab-click="tabItemCallback"
              >
                <el-tab-pane
                  v-for="tabItem in timeTab"
                  :name="tabItem.value"
                  :label="tabItem.label"
                  :key="tabItem.value"
                >
                </el-tab-pane>
              </el-tabs>
              <div class="interpretation-tab">
                <div class="interp-title">{{ $t("trade.text29") }}</div>
                <div v-if="analysisObject.summary">
                  <canvas id="interpretationCanvas"></canvas>
                  <div
                    :class="[
                      'sell',
                      analysisObject.summary.result == $t('trade.text30') ||
                      analysisObject.summary.result == $t('trade.text31')
                        ? 'up'
                        : analysisObject.summary.result == $t('trade.text32') ||
                          analysisObject.summary.result == $t('trade.text33')
                        ? 'down'
                        : 'neutral',
                    ]"
                  >
                    {{ analysisObject.summary.result }}
                  </div>
                  <div class="interp-flex">
                    <div class="interp-flex-item">
                      <div class="interp-flex-top down">
                        {{ analysisObject.summary.sell }}
                      </div>
                      <div class="interp-flex-bottom">
                        {{ $t("trade.text32") }}
                      </div>
                    </div>
                    <div class="interp-flex-item">
                      <div class="interp-flex-top">
                        {{ analysisObject.summary.neutral }}
                      </div>
                      <div class="interp-flex-bottom">
                        {{ $t("trade.text34") }}
                      </div>
                    </div>
                    <div class="interp-flex-item">
                      <div class="interp-flex-top up">
                        {{ analysisObject.summary.buy }}
                      </div>
                      <div class="interp-flex-bottom">
                        {{ $t("trade.text30") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="long_empty" v-else>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                </div>
                <div class="interp-title">{{ $t("trade.text35") }}</div>
                <div v-if="analysisObject.oscillators">
                  <canvas id="interpretationCanvas2"></canvas>
                  <div
                    :class="[
                      'sell',
                      analysisObject.oscillators.result == $t('trade.text30') ||
                      analysisObject.oscillators.result == $t('trade.text31')
                        ? 'up'
                        : analysisObject.oscillators.result ==
                            $t('trade.text32') ||
                          analysisObject.oscillators.result ==
                            $t('trade.text33')
                        ? 'down'
                        : 'neutral',
                    ]"
                  >
                    {{ analysisObject.oscillators.result }}
                  </div>
                  <div class="interp-flex">
                    <div class="interp-flex-item">
                      <div class="interp-flex-top down">
                        {{ analysisObject.oscillators.sell }}
                      </div>
                      <div class="interp-flex-bottom">
                        {{ $t("trade.text32") }}
                      </div>
                    </div>
                    <div class="interp-flex-item">
                      <div class="interp-flex-top">
                        {{ analysisObject.oscillators.neutral }}
                      </div>
                      <div class="interp-flex-bottom">
                        {{ $t("trade.text34") }}
                      </div>
                    </div>
                    <div class="interp-flex-item">
                      <div class="interp-flex-top up">
                        {{ analysisObject.oscillators.buy }}
                      </div>
                      <div class="interp-flex-bottom">
                        {{ $t("trade.text30") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="long_empty" v-else>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                </div>
                <div
                  class="interp-table"
                  v-if="analysisObject.oscillatorsAndMoving.length != 0"
                >
                  <div class="interp-th">
                    <div class="interp-td-1">{{ $t("trade.text37") }}</div>
                    <div class="interp-td-2">{{ $t("trade.text38") }}</div>
                    <div class="interp-td-3">{{ $t("trade.text39") }}</div>
                  </div>
                  <div
                    class="interp-tr"
                    v-for="(item, index) in analysisObject.oscillatorsAndMoving"
                    v-show="index < 3 || moreFlag"
                    :key="item.name"
                  >
                    <div class="interp-td-1">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="item.name"
                        placement="left-start"
                      >
                        <div class="tooltip">{{ item.name }}</div>
                      </el-tooltip>
                    </div>
                    <div class="interp-td-2">{{ item.value }}</div>
                    <div
                      :class="[
                        'interp-td-3',
                        item.result == $t('trade.text30') ||
                        item.result == $t('trade.text31')
                          ? 'up'
                          : item.result == $t('trade.text32') ||
                            item.result == $t('trade.text33')
                          ? 'down'
                          : '',
                      ]"
                    >
                      {{ item.result }}
                    </div>
                  </div>
                  <div class="more" @click="moreFlag = true" v-if="!moreFlag">
                    {{ $t("trade.text42") }}
                  </div>
                </div>
                <div class="short_empty" v-else>
                  <div class="empty_line">
                    <div class="left_line"></div>
                    <div class="right_line">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="empty_line">
                    <div class="left_line"></div>
                    <div class="right_line">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="empty_line">
                    <div class="left_line"></div>
                    <div class="right_line">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="empty_line">
                    <div class="left_line"></div>
                    <div class="right_line">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div class="interp-title">{{ $t("trade.text40") }}</div>
                <div v-if="analysisObject.movingAverages">
                  <canvas id="interpretationCanvas3"></canvas>
                  <div
                    :class="[
                      'sell',
                      analysisObject.movingAverages.result ==
                        $t('trade.text30') ||
                      analysisObject.movingAverages.result == $t('trade.text31')
                        ? 'up'
                        : analysisObject.movingAverages.result ==
                            $t('trade.text32') ||
                          analysisObject.movingAverages.result ==
                            $t('trade.text33')
                        ? 'down'
                        : 'neutral',
                    ]"
                  >
                    {{ analysisObject.oscillators.result }}
                  </div>
                  <div class="interp-flex">
                    <div class="interp-flex-item">
                      <div class="interp-flex-top down">
                        {{ analysisObject.movingAverages.sell }}
                      </div>
                      <div class="interp-flex-bottom">
                        {{ $t("trade.text32") }}
                      </div>
                    </div>
                    <div class="interp-flex-item">
                      <div class="interp-flex-top">
                        {{ analysisObject.movingAverages.neutral }}
                      </div>
                      <div class="interp-flex-bottom">
                        {{ $t("trade.text34") }}
                      </div>
                    </div>
                    <div class="interp-flex-item">
                      <div class="interp-flex-top up">
                        {{ analysisObject.movingAverages.buy }}
                      </div>
                      <div class="interp-flex-bottom">
                        {{ $t("trade.text30") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="long_empty" v-else>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                </div>
                <div
                  class="interp-table"
                  v-if="analysisObject.movingAveragesItems.length != 0"
                >
                  <div class="interp-th">
                    <div class="interp-td-1">{{ $t("trade.text41") }}</div>
                    <div class="interp-td-2">{{ $t("trade.text38") }}</div>
                    <div class="interp-td-3">{{ $t("trade.text39") }}</div>
                  </div>
                  <div
                    class="interp-tr"
                    v-for="(item, index) in analysisObject.movingAveragesItems"
                    v-show="index < 3 || moreFlag2"
                    :key="item.name + index"
                  >
                    <div class="interp-td-1">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="
                          item.chooseVal
                            ? item.name + '(' + item.chooseVal + ')'
                            : item.name
                        "
                        placement="left-start"
                      >
                        <div class="tooltip">
                          {{
                            item.chooseVal
                              ? item.name + "(" + item.chooseVal + ")"
                              : item.name
                          }}
                        </div>
                      </el-tooltip>
                    </div>
                    <div class="interp-td-2">{{ item.value }}</div>
                    <div
                      :class="[
                        'interp-td-3',
                        item.result == $t('trade.text30') ||
                        item.result == $t('trade.text31')
                          ? 'up'
                          : item.result == $t('trade.text32') ||
                            item.result == $t('trade.text33')
                          ? 'down'
                          : '',
                      ]"
                    >
                      {{ item.result }}
                    </div>
                  </div>
                  <div class="more" @click="moreFlag2 = true" v-if="!moreFlag2">
                    {{ $t("trade.text42") }}
                  </div>
                </div>
                <div class="short_empty" v-else>
                  <div class="empty_line">
                    <div class="left_line"></div>
                    <div class="right_line">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="empty_line">
                    <div class="left_line"></div>
                    <div class="right_line">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="empty_line">
                    <div class="left_line"></div>
                    <div class="right_line">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="empty_line">
                    <div class="left_line"></div>
                    <div class="right_line">
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div class="interp-title">{{ $t("trade.text43") }}</div>
                <div
                  class="interp-table2"
                  v-if="analysisObject.pivots.length != 0"
                >
                  <div class="interp-th">
                    <div class="interp-td-1">{{ $t("trade.text43") }}</div>
                    <div class="interp-td-2">{{ $t("trade.text44") }}</div>
                    <div class="interp-td-3">{{ $t("trade.text45") }}</div>
                    <div class="interp-td-4">DM</div>
                  </div>
                  <div
                    class="interp-tr"
                    v-for="(item, index) in analysisObject.pivots"
                    :key="item.pivots + index"
                  >
                    <div class="interp-td-1">{{ item.pivots }}</div>
                    <div class="interp-td-2">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="item.classic"
                        placement="top-start"
                      >
                        <div class="tooltipPoint">{{ item.classic }}</div>
                      </el-tooltip>
                    </div>
                    <div class="interp-td-3">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="item.fibonacci"
                        placement="top-start"
                      >
                        <div class="tooltipPoint">{{ item.fibonacci }}</div>
                      </el-tooltip>
                    </div>
                    <div class="interp-td-4">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="item.dm"
                        placement="top-start"
                      >
                        <div class="tooltipPoint">{{ item.dm }}</div>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div class="long_empty" v-else>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                  <div class="empty_line"></div>
                </div>
              </div>
              <div class="tab-content"></div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  onMounted,
  watch,
  inject,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
// import { get } from 'jquery';
import apiServer from "@/api/request";
import socket from "./datafeeds/socket.js";
import Queue from "./datafeeds/queue.js";
import { toRaw } from "@vue/reactivity";
import { setTimeout } from "timers";
import { useI18n } from "vue-i18n";
import { isMobile } from '@/until/common';

export default {
  name: "offerAnalysisRule",
  components: {},
  setup(props) {
    const emitt = inject("emitt");
    const store = useStore();
    const { t } = useI18n();

    const activeSymbol = computed({
      get: () => store.state.home.activeSymbolItem,
    });
    const list = [];
    let myCanvas; // canvas对象
    let ctx; // 绘画对象
    const queueOffer = new Queue(); // 卖价队列
    const queueBid = new Queue(); // 买价队列
    let canvasW; // canvas宽度
    let { prevClose } = activeSymbol.value; // 昨收
    let decimalPlace = Number(activeSymbol.value.decimalPlace); // 小数位数
    let { bidForDiffer } = activeSymbol.value; // 买价加点点差
    let { askForDiffer } = activeSymbol.value; // 卖价加点点差
    // websocket地址
    const qouteWsUrl = computed({ get: () => store.state.trade.qouteWsUrl });
    let socketL = null;
    const activeName = ref(t("trade.text13")); //
    const offerData = ref(""); // 卖价
    const bidData = ref(""); // 买价
    const preData = ref(""); // 涨跌百分比
    const preValueData = ref(""); // 涨跌值
    const lowData = ref(""); // 最低
    const highData = ref(""); // 最高
    let ordinateArr = []; // 纵坐标数组
    const popconfirmFlag = ref(false);
    const upColor = computed({ get: () => store.state.home.contentColor }); // 当前涨跌色
    const moreFlag = ref(false);
    const moreFlag2 = ref(false);
    let cell = null; // 纵坐标每格
    // 主题色
    const nowMainColor = computed({ get: () => store.state.home.mainColor });
    // 监听当前合约修改
    emitt.$sub("activeSymbolChange", func);
    function func() {
      changeTradeView();
      getAnalysisList();
      getShortList();
      getRule();
      const jumpParams = {
        offerData: offerData.value,
        bidData: bidData.value,
      };
      emitt.$pub("refreshPrice", jumpParams);
    }
    function callback(key, e) {
      let name = toRaw(toRaw(key).props.name);
      console.log("key", toRaw(toRaw(key).props));

      if (name == t("trade.text15")) {
        getShortList();
        getAnalysisList();
      }
    }
    //时间切换
    function tabItemCallback(key) {
      getAnalysisList();
    }
    onBeforeUnmount(() => {
      if (socketL) {
        socketL.close();
        socketL = null;
      }
      window.onresize = null;
      emitt.$unsub("activeSymbolChange", func);
      window.onresize = function () {
        setRem();
      };
    });
    let upStyle = "#FB5B39";
    let downStyle = "#16BB94";
    let ringUpStyle = "#FB6669";
    let ringUpStyle2 = "#FDB2B4";
    let ringDownStyle = "#16BB94";
    let ringDownStyle2 = "#8ADDC9";

    watch(upColor, (upColor, prevUpColor) => {
      if (upColor && upColor !== prevUpColor) {
        upAndDownColor();
        canvasWidth();
        initDrawDrid();
        ordinate();
        drawLine();
      }
    });
    upAndDownColor();
    //确认涨跌色
    function upAndDownColor() {
      switch (upColor.value) {
        case "red":
          upStyle = "#FB5B39";
          downStyle = "#16BB94";
          ringUpStyle = "#FB6669";
          ringUpStyle2 = "#FDB2B4";
          ringDownStyle = "#16BB94";
          ringDownStyle2 = "#8ADDC9";
          break;
        case "green":
          upStyle = "#16BB94";
          downStyle = "#FB5B39";
          ringUpStyle = "#16BB94";
          ringUpStyle2 = "#8ADDC9";
          ringDownStyle = "#FB6669";
          ringDownStyle2 = "#FDB2B4";
          break;
        case "blue":
          upStyle = "#1B67FF";
          downStyle = "#FB5B39";
          ringUpStyle = "#1B67FF";
          ringUpStyle2 = "#7DA9FF";
          ringDownStyle = "#FB6669";
          ringDownStyle2 = "#FDB2B4";
          break;
      }
    }
    // 重置数据
    function initData() {
      queueOffer.clear();
      queueBid.clear();
      prevClose = activeSymbol.value.prevClose; // 昨收
      decimalPlace = Number(activeSymbol.value.decimalPlace); // 小数位数
      bidForDiffer = activeSymbol.value.bidForDiffer; // 买价加点点差
      askForDiffer = activeSymbol.value.askForDiffer; // 卖价加点点差
      offerData.value = (
        Number(activeSymbol.value.askPrice) + Number(askForDiffer)
      ).toFixed(decimalPlace);
      bidData.value = (
        Number(activeSymbol.value.bidPrice) + Number(bidForDiffer)
      ).toFixed(decimalPlace);
      preValueData.value = (offerData.value - Number(prevClose)).toFixed(
        decimalPlace
      );
      preData.value = `${Number(
        ((offerData.value - Number(prevClose)) / Number(prevClose)) * 100
      ).toFixed(2)}%`;
      lowData.value = activeSymbol.value.low;
      highData.value = activeSymbol.value.high;
      queueOffer.push(offerData.value);
      queueBid.push(bidData.value);
      emitt.$pub("passByValue", {
        symbol: activeSymbol.value.symbol,
        offerDataPass: offerData.value,
        askPrice: activeSymbol.value.askPrice,
        bidPrice: activeSymbol.value.bidPrice,
        preValueDataPass: preValueData.value,
        preDataPass: preData.value,
        highDataPass: highData.value,
        lowDataPass: lowData.value,
      });
    }

    onMounted(() => {
      myCanvas = document.getElementById("myCanvas");
      ctx = myCanvas.getContext("2d");
      window.onresize = function () {
        canvasWidth();
        initDrawDrid();
        ordinate();
        drawLine();
        queueOffer[0] = queueOffer[queueOffer.length];
        queueBid[0] = queueBid[queueBid.length];
        changeTradeView();
        setRem();
      };
    });

    const baseSize = 16;

    // 设置 rem 函数
    function setRem() {
      let val;
      let clientWidth = document.documentElement.clientWidth;
      // 判断浏览器函数
      if (isMobile()) {
        val = 750; // 移动端
      } else {
        val = 1920;
        // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
        if (clientWidth < 1240) {
          clientWidth = 1240;
        }
      }

      const scale = clientWidth / val;
      // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
      document.documentElement.style.fontSize =
        baseSize * Math.min(scale, 1) + "px";
    }
    let b = 0;
    function changeTradeView() {
      initData();
      canvasWidth();
      initDrawDrid();
      ordinate();
      drawLine();
      initSocket2();
    }

    // canvas适配宽度
    function canvasWidth() {
      canvasW = document.getElementsByClassName("offer_analysis_rule")[0]
        .clientWidth;
      document.getElementById("myCanvas").width = canvasW;
      const length = parseInt(((canvasW - 50) / 16) * 7);
      queueOffer.setLength(length);
      queueBid.setLength(length);
    }
    // 计算纵坐标系
    function ordinate() {
      const max = Number(queueBid.getMax());
      const min = Number(queueOffer.getMin());
      const ordMax = Number(maxCeil(max));
      const ordMin = Number(minFloor(min));
      cell = Number(((ordMax - ordMin) / 8).toFixed(decimalPlace));
      ordinateArr = [ordMax.toFixed(decimalPlace)];
      for (let i = 0; i < 8; i++) {
        const c = Number(ordinateArr[i]) - cell;
        ordinateArr.push(c.toFixed(decimalPlace));
      }
      drawOrdinate(ordinateArr);
    }
    // 画线
    function drawLine() {
      const canvas = document.getElementById("myCanvas");
      const context = canvas.getContext("2d");
      context.save();
      const offerArr = queueOffer.get(); // 卖价数组
      const bidArr = queueBid.get(); // 买价数组
      context.beginPath();
      const px =
        (Number(ordinateArr[0]) - Number(ordinateArr[ordinateArr.length - 1])) /
        400; // 每一个像素代表多少值
      const bidBeginY = Number(ordinateArr[0] - Number(bidArr[0])) / px + 10; // 买价起点Y值
      context.moveTo(0, bidBeginY);
      context.lineWidth = 1.5; // 设置线宽状态
      context.strokeStyle = upStyle;
      context.setLineDash([0, 0]);
      for (let i = 1; i < bidArr.length; i++) {
        context.lineTo(
          2 * i,
          Number(ordinateArr[0] - Number(bidArr[i])) / px + 10
        ); // 画折线
      }
      context.lineTo(
        canvasW - 50,
        Number(ordinateArr[0] - Number(bidArr[bidArr.length - 1])) / px + 10
      ); // 画指向线
      context.fillStyle = upStyle;
      context.fillRect(
        canvasW - 50,
        Number(ordinateArr[0] - Number(bidArr[bidArr.length - 1])) / px +
          10 -
          8,
        50,
        16
      );
      context.font = "normal bold 8pt Calibri";
      context.textAlign = "left";
      context.fillStyle = "white";
      context.textBaseline = "top";
      context.fillText(
        bidArr[bidArr.length - 1],
        canvasW - 46,
        Number(ordinateArr[0] - Number(bidArr[bidArr.length - 1])) / px - 5 + 10
      );
      context.stroke();

      context.beginPath();
      const offerBeginY =
        Number(ordinateArr[0] - Number(offerArr[0])) / px + 10; // 卖价起点Y值
      context.moveTo(0, offerBeginY);

      context.strokeStyle = downStyle;
      for (let i = 1; i < offerArr.length; i++) {
        context.lineTo(
          2 * i,
          Number(ordinateArr[0] - Number(offerArr[i])) / px + 10
        );
      }
      context.lineTo(
        canvasW - 50,
        Number(ordinateArr[0] - Number(offerArr[offerArr.length - 1])) / px + 10
      );
      context.fillStyle = downStyle;
      context.fillRect(
        canvasW - 50,
        Number(ordinateArr[0] - Number(offerArr[offerArr.length - 1])) / px -
          8 +
          10,
        50,
        16
      );
      context.font = "normal bold 8pt Calibri";
      context.textAlign = "left";
      context.fillStyle = "white";
      context.textBaseline = "top";
      context.fillText(
        offerArr[offerArr.length - 1],
        canvasW - 46,
        Number(ordinateArr[0] - Number(offerArr[offerArr.length - 1])) / px -
          5 +
          10
      );
      context.stroke();
    }
    // 画纵坐标系
    function drawOrdinate(e) {
      const canvas = document.getElementById("myCanvas");
      const context = canvas.getContext("2d");
      context.font = "8pt Calibri";
      context.textAlign = "left";
      context.fillStyle = "#919CAD";
      context.textBaseline = "top";
      for (let i = 0; i < e.length; i++) {
        context.fillText(e[i], canvasW - 45, 50 * i + 5);
      }
      drawLine();
    }

    // 最大值向上取整
    function maxCeil(e) {
      let max = e * Math.pow(10, decimalPlace - 1);
      max = (Math.ceil(max) + 1) / Math.pow(10, decimalPlace - 1);
      return max.toFixed(decimalPlace);
    }
    // 最小值向下取整
    function minFloor(e) {
      let min = e * Math.pow(10, decimalPlace - 1);
      min = (Math.floor(min) - 1) / Math.pow(10, decimalPlace - 1);
      return min.toFixed(decimalPlace);
    }
    // 数据展示
    function showData(data) {
      offerData.value = (Number(data.offer) + Number(askForDiffer)).toFixed(
        decimalPlace
      );
      bidData.value = (Number(data.bid) + Number(bidForDiffer)).toFixed(
        decimalPlace
      );
      preValueData.value = (offerData.value - Number(prevClose)).toFixed(
        decimalPlace
      );
      preData.value = `${Number(
        ((offerData.value - Number(prevClose)) / Number(prevClose)) * 100
      ).toFixed(2)}%`;
      lowData.value = data.low;
      highData.value = data.high;
      queueBid.push(bidData.value);
      queueOffer.push(offerData.value);
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, myCanvas.width, myCanvas.height);
      const jumpParams = {
        offerData: offerData.value,
        bidData: bidData.value,
      };
      emitt.$pub("refreshPrice", jumpParams);
      emitt.$pub("passByValue", {
        symbol: data.symbol,
        offerDataPass: offerData.value,
        askPrice: data.offer,
        bidPrice: data.bid,
        preValueDataPass: preValueData.value,
        preDataPass: preData.value,
        highDataPass: highData.value,
        lowDataPass: lowData.value,
        timestamp: data.timestamp
      });
      initDrawDrid();
      ordinate();
    }
    let a = 0;
    // 初始化websocket
    function initSocket2() {
      const paramSymbol = activeSymbol.value.symbol;
      console.log(a++, "initSocket2");
      if (socketL) {
        socketL.close();
        socketL = null;
      }

      const url = `${qouteWsUrl.value}/tickdata?symbol=${paramSymbol}`;
      socketL = new socket(url);
      socketL.on("message", showData);
    }
    // 画背景板
    function initDrawDrid(canvas) {
      var canvas = canvas || document.getElementById("myCanvas");
      // 设置间隔
      const spaceY = 50;
      const spaceX = (canvasW - 50) / 8;

      // 定义当前坐标
      let x = 0;
      let y = 0;
      // 设置虚线
      ctx.setLineDash([5, 2]);
      ctx.strokeStyle = "#EBEBEB";
      // 绘制水平方向的网格线
      for (y = 0; y < canvas.height; y += spaceY) {
        // 开启路径
        ctx.beginPath();
        ctx.moveTo(0, y + 10);
        ctx.lineTo(canvasW - 50, y + 10);
        ctx.stroke();
      }
      // 绘制垂直方向的网格线
      for (x = 0; x < 9; x++) {
        // 开启路径
        ctx.beginPath();
        ctx.moveTo(x * spaceX, 10);
        ctx.lineTo(x * spaceX, canvas.height - 10);
        ctx.stroke();
      }
    }
    /** 多空分析 */
    const timeTab = reactive([
      {
        label: "5" + t("trade.text24"),
        value: "2",
      },
      {
        label: "15" + t("trade.text24"),
        value: "3",
      },
      {
        label: "1" + t("trade.text25"),
        value: "4",
      },
      {
        label: "4" + t("trade.text25"),
        value: "5",
      },
      {
        label: "1" + t("trade.text26"),
        value: "6",
      },
      {
        label: "1" + t("trade.text27"),
        value: "7",
      },
      {
        label: t("trade.text28"),
        value: "8",
      },
    ]);
    const activTab = ref("2");
    const shortList = reactive([]);
    const analysisObject = reactive({
      tips: {}, // 提示
      summary: {}, // 总结
      oscillators: {}, // 震荡指标
      movingAverages: {}, // 移动平均线
      oscillatorsAndMoving: [], // 震荡指标项
      movingAveragesItems: [], // 移动平均线指标项
      pivots: [], // 枢纽点
    });

    // 获取多空数据
    const getShortList = () => {
      apiServer.Trends({ symbol: activeSymbol.value.symbol }).then((res) => {
        if (res.status === 1) {
          shortList.length = 0;
          shortList.push(...res.data.list);
        }
      });
    };
    const getAnalysisList = () => {
      apiServer
        .Analysis({
          symbol: activeSymbol.value.symbol,
          timeTag: activTab.value,
        })
        .then((res) => {
          if (res.status === 1) {
            analysisObject.oscillatorsAndMoving.length = 0;
            analysisObject.movingAveragesItems.length = 0;
            analysisObject.pivots.length = 0;
            Object.assign(analysisObject.tips, res.data.tips);
            Object.assign(analysisObject.summary, res.data.summary);
            Object.assign(analysisObject.oscillators, res.data.oscillators);
            Object.assign(
              analysisObject.movingAverages,
              res.data.movingAverages
            );
            analysisObject.oscillatorsAndMoving.push(
              ...res.data.oscillatorsAndMoving
            );
            analysisObject.pivots.push(...res.data.pivots);
            const arr = [];
            for (let i = 0; i < res.data.movingAveragesItems.norm.length; i++) {
              for (
                let j = 0;
                j < res.data.movingAveragesItems.norm[i].value.length;
                j++
              ) {
                const item = Object.assign(
                  res.data.movingAveragesItems.norm[i].value[j],
                  { chooseVal: res.data.movingAveragesItems.norm[i].choose[j] }
                );
                arr.push(item);
              }
            }
            arr.sort((a, b) => a.chooseVal - b.chooseVal);
            analysisObject.movingAveragesItems.push(...arr);
            analysisObject.movingAveragesItems.push(
              ...res.data.movingAveragesItems.others
            );
            console.log("activeName.valueactiveName.value", analysisObject);
            if (activeName.value == t("trade.text15")) {
              analysisDraw();
              analysisDraw2();
              analysisDraw3();
            }
          }
        });
    };
    function drawCricle(ctx, result, resultTag) {
      // 画进度环
      ctx.beginPath();
      ctx.moveTo(interpretationCanvasW * 0.5, interpretationCanvasW * 0.5);
      ctx.arc(
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.35,
        Math.PI,
        Math.PI + Math.PI * 0.2
      );
      ctx.closePath();
      ctx.fillStyle = ringDownStyle;
      ctx.fill();
      ctx.beginPath();
      ctx.moveTo(interpretationCanvasW * 0.5, interpretationCanvasW * 0.5);
      ctx.arc(
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.35,
        Math.PI + Math.PI * 0.2,
        Math.PI + Math.PI * 0.4
      );
      ctx.closePath();
      ctx.fillStyle = ringDownStyle2;
      ctx.fill();
      ctx.beginPath();
      ctx.moveTo(interpretationCanvasW * 0.5, interpretationCanvasW * 0.5);
      ctx.arc(
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.35,
        Math.PI + Math.PI * 0.4,
        Math.PI + Math.PI * 0.6
      );
      ctx.closePath();
      ctx.fillStyle = "#C0C9D6";
      ctx.fill();
      ctx.beginPath();
      ctx.moveTo(interpretationCanvasW * 0.5, interpretationCanvasW * 0.5);
      ctx.arc(
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.35,
        Math.PI + Math.PI * 0.6,
        Math.PI + Math.PI * 0.8
      );
      ctx.closePath();
      ctx.fillStyle = ringUpStyle2;
      ctx.fill();
      ctx.beginPath();
      ctx.moveTo(interpretationCanvasW * 0.5, interpretationCanvasW * 0.5);
      ctx.arc(
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.35,
        Math.PI + Math.PI * 0.8,
        Math.PI + Math.PI * 1
      );
      ctx.closePath();
      ctx.fillStyle = ringUpStyle;
      ctx.fill();
      ctx.beginPath();
      ctx.moveTo(interpretationCanvasW * 0.5, interpretationCanvasW * 0.5);
      ctx.arc(
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.25,
        0,
        2 * Math.PI
      );
      ctx.closePath();
      ctx.fillStyle = "#ffffff";
      ctx.fill();
      drawArrow(ctx, result, resultTag);
    }
    //
    function drawArrow(ctx, result, resultTag) {
      ctx.beginPath();
      ctx.moveTo(interpretationCanvasW * 0.5, interpretationCanvasW * 0.5);
      ctx.arc(
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.5 - interpretationCanvasW * 0.015,
        interpretationCanvasW * 0.03,
        0,
        2 * Math.PI
      );
      ctx.closePath();
      if (result == t("trade.text30") || result == t("trade.text31")) {
        ctx.fillStyle = ringUpStyle;
      } else if (result == t("trade.text32") || result == t("trade.text33")) {
        ctx.fillStyle = ringDownStyle;
      } else {
        ctx.fillStyle = "#C0C9D6";
      }
      ctx.fill();
      ctx.beginPath();
      ctx.lineWidth = 2; // 设置线宽状态
      if (result == t("trade.text30") || result == t("trade.text31")) {
        ctx.strokeStyle = ringUpStyle;
      } else if (result == t("trade.text32") || result == t("trade.text33")) {
        ctx.strokeStyle = ringDownStyle;
      } else {
        ctx.strokeStyle = "#C0C9D6";
      }
      ctx.moveTo(
        interpretationCanvasW * 0.5,
        interpretationCanvasW * 0.5 - interpretationCanvasW * 0.01
      );
      switch (resultTag) {
        case 1:
          ctx.lineTo(interpretationCanvasW * 0.3, interpretationCanvasW * 0.44);
          break;
        case 2:
          ctx.lineTo(
            interpretationCanvasW * 0.39,
            interpretationCanvasW * 0.35
          );
          break;
        case 3:
          ctx.lineTo(interpretationCanvasW * 0.5, interpretationCanvasW * 0.3);
          break;
        case 4:
          ctx.lineTo(
            interpretationCanvasW * 0.62,
            interpretationCanvasW * 0.35
          );
          break;
        case 5:
          ctx.lineTo(interpretationCanvasW * 0.7, interpretationCanvasW * 0.44);
          break;
      }
      ctx.closePath();
      ctx.stroke();
    }
    let interpretationCanvasW = 0;
    function analysisDraw() {
      interpretationCanvasW =
        document.getElementsByClassName("short-empty-chart")[0].clientWidth -
        32;
      document.getElementById("interpretationCanvas").width =
        interpretationCanvasW;
      document.getElementById("interpretationCanvas").height =
        interpretationCanvasW / 2 + 5;
      const canvas = document.getElementById("interpretationCanvas");
      const context = canvas.getContext("2d");
      drawCricle(
        context,
        analysisObject.summary.result,
        analysisObject.summary.resultTag
      );
      writeWord(context, true);
    }
    function analysisDraw2() {
      document.getElementById("interpretationCanvas2").width =
        interpretationCanvasW;
      document.getElementById("interpretationCanvas2").height =
        interpretationCanvasW / 2 + 5;
      const canvas = document.getElementById("interpretationCanvas2");
      const context = canvas.getContext("2d");
      drawCricle(
        context,
        analysisObject.oscillators.result,
        analysisObject.oscillators.resultTag
      );
      writeWord(context);
    }
    function analysisDraw3() {
      document.getElementById("interpretationCanvas3").width =
        interpretationCanvasW;
      document.getElementById("interpretationCanvas3").height =
        interpretationCanvasW / 2 + 5;
      const canvas = document.getElementById("interpretationCanvas3");
      const context = canvas.getContext("2d");
      drawCricle(
        context,
        analysisObject.movingAverages.result,
        analysisObject.movingAverages.resultTag
      );
      writeWord(context);
    }
    //写字
    function writeWord(ctx, e) {
      ctx.font = "8pt Calibri";
      ctx.textAlign = "right";
      ctx.fillStyle = "#919CAD";
      ctx.textBaseline = "top";
      ctx.fillText(
        t("trade.text36"),
        0.13 * interpretationCanvasW,
        interpretationCanvasW * 0.5 - 28
      );
      ctx.fillText(
        t("trade.text32"),
        0.13 * interpretationCanvasW,
        interpretationCanvasW * 0.5 - 13
      );
      ctx.fillText(
        t("trade.text32"),
        0.27 * interpretationCanvasW,
        interpretationCanvasW * 0.15
      );
      ctx.textAlign = "center";
      ctx.fillText(
        t("trade.text34"),
        0.5 * interpretationCanvasW,
        interpretationCanvasW * 0.05
      );
      ctx.fillText(
        t("trade.text30"),
        0.77 * interpretationCanvasW,
        interpretationCanvasW * 0.15
      );
      ctx.textAlign = "right";
      ctx.fillText(
        t("trade.text36"),
        interpretationCanvasW - 10,
        interpretationCanvasW * 0.5 - 28
      );
      ctx.fillText(
        t("trade.text30"),
        interpretationCanvasW - 10,
        interpretationCanvasW * 0.5 - 13
      );
    }

    /** 规则 */
    const rules = reactive({
      list: [],
      time: [],
    });
    // 获取规则详情页
    function getRule() {
      apiServer.tradeRule({ symbol: activeSymbol.value.symbol }).then((res) => {
        if (res.status === 1) {
          rules.list.length = 0;
          rules.list.push(...res.data.list);
          rules.time.length = 0;
          rules.time.push(...res.data.time);
        }
      });
    }
    getRule();
    return {
      activeSymbol,
      offerData,
      bidData,
      preData,
      highData,
      lowData,
      preValueData,
      rules,
      shortList,
      timeTab,
      analysisObject,
      activTab,
      popconfirmFlag,
      analysisDraw,
      callback,
      analysisDraw2,
      moreFlag,
      moreFlag2,
      tabItemCallback,
      activeName,
    };
  },
};
</script>
<style lang="less" scoped>
.offer_analysis_rule {
  background: @white;
  .offer {
    height: 512px; /*no*/
    overflow-y: auto;
    .short-empty-chart {
      padding: 16px;
      padding-top: 0;
    }
    .short-chart {
      margin-bottom: 10px; /*no*/
    }
    .long_empty {
      margin-top: 20px; /*no*/
      .empty_line {
        width: 100%;
        height: 12px; /*no*/
        border-radius: 2px; /*no*/
        background: @bg-color;
        margin-bottom: 16px; /*no*/
      }
    }
    .short_empty {
      .empty_line {
        display: flex;
        justify-content: space-between;
        margin-top: 16px; /*no*/
        .left_line {
          width: 120px; /*no*/
          background: @bg-color;
          height: 12px; /*no*/
        }
        .right_line {
          width: 130px; /*no*/
          display: flex;
          justify-content: space-between;
          div {
            width: 60px;
            background: @bg-color;
            height: 12px; /*no*/
          }
        }
      }
    }
    .short-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 10px;
    }
    .short-chart-box {
      width: 100%;
      padding: 4px 0 8px;
    }
    .up-line,
    .down-line {
      float: left;
      height: 4px;
      border-radius: 100px 0 8px 100px;
    }
    .up-line {
      border-radius: 0 100px 100px 0;
      position: relative;
      &::before {
        position: absolute;
        width: 2px;
        height: 4px;
        left: -1px;
        top: 0;
        content: " ";
        display: block;
        transform: rotateZ(25deg);
        background: @white;
      }
    }
    .rule-content {
      padding: 0 16px 16px;
      font-size: 12px;
      text-align: left;
    }
    .rule-item {
      display: flex;
      justify-content: space-between;
      color: @text-color;
      line-height: 2;
    }
    .rule-title {
      width: 150px;
      color: @text-color-secondary;
    }
    .offer-title {
      color: @text-color;
      font-weight: 600;
      font-size: 13px;
      text-align: left;
      position: relative;
      z-index: 9;
      margin-bottom: 6px;
      i {
        color: @primary-color;
        font-size: 14px;
        cursor: pointer;
      }
      .popconfirm {
        width: 80%;
        padding: 13px 8px;
        font-size: 12px;
        box-shadow: 0px 2px 14px 0px #c8cdd6;
        border-radius: 2px;
        position: absolute;
        left: 46px;
        top: 18px;
        z-index: 99;
        background: @white;
        .popconfirm-title {
          color: @primary-color;
          margin-top: 8px;
        }
        .popconfirm-btn {
          margin-top: 15px;
        }
      }
    }
    .rule-time-table {
      border-top: 1px solid @border-color-base;
      border-right: 1px solid @border-color-base;
      color: @text-color;
      line-height: 1.4;
      margin-top: 10px;
    }
    .rule-time-table-header {
      background: @content-bg-color;
    }
    .rule-time-table-row {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      text-align: center;
    }
    .rule-time-col {
      border-left: 1px solid @border-color-base;
      border-bottom: 1px solid @border-color-base;
      width: 34%;
      padding: 4px;
    }
  }
  .myCanvas {
    overflow: hidden;
    background: white;
  }
  .interpretation-tab {
    .ant-tabs-tab {
      margin: 0 8px;
      padding: 8px 2px;
      font-size: 12px;
    }
    .sell {
      text-align: center;
    }
    .neutral {
      color: #c0c9d6;
    }
    .interp-flex {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .interp-flex-item {
        width: 33.3333%;
        .interp-flex-top {
          font-weight: 600;
        }
        .interp-flex-bottom {
          font-size: 12px;
          color: @text-color-secondary;
        }
      }
    }
    .interp-table {
      font-size: 12px;
      margin-top: 16px;
      .interp-th {
        color: @text-color-secondary;
      }
      .interp-th,
      .interp-tr {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin-top: 6px;
        .interp-td-1,
        .interp-td-2,
        .interp-td-3.interp-td-4 {
        }
        .tooltip {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }
        .interp-td-1 {
          width: 55%;
        }
        .interp-td-2 {
          width: 35%;
        }
        .interp-td-3 {
          width: 12%;
          text-align: right;
        }
      }
      .more {
        color: @primary-color;
        font-size: 12px;
        text-align: right;
        cursor: pointer;
      }
    }
    .interp-table2 {
      font-size: 12px;
      margin-top: 16px;
      .tooltipPoint {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 3px;
      }
      .interp-th {
        color: @text-color-secondary;
      }
      .interp-th,
      .interp-tr {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin-top: 6px;
        .interp-td-1 {
          width: 15%;
        }
        .interp-td-2 {
          width: 30%;
          text-align: right;
        }
        .interp-td-3 {
          width: 30%;
          text-align: right;
        }
        .interp-td-4 {
          width: 25%;
          text-align: right;
        }
      }
    }
    .interp-title {
      font-weight: bold;
      text-align: left;
      margin-top: 10px;
    }
  }
  .chart-item {
    margin-bottom: 8px; /*no*/
  }
}
</style>
<style lang="less">
.offer_analysis_rule {
  .el-tabs__item {
    margin-right: 50px;
    padding: 0 !important;
    height: 24px !important;
    line-height: 24px !important;
    font-size: 14px !important;
  }
  .el-tabs__nav-next,
  .el-tabs__nav-prev {
    line-height: 29px !important;
  }
  .interpretation-box {
    .el-tabs__item {
      margin-right: 21px;
      padding: 0 !important;
      height: 24px !important;
      line-height: 24px !important;
      font-size: 12px !important;
    }
    .el-tabs__nav-next,
    .el-tabs__nav-prev {
      line-height: 29px !important;
    }
  }
  .el-tabs__nav-wrap {
    margin-bottom: 15px !important;
  }
  .el-tabs__item {
    height: 40px !important;
    line-height: 40px !important;
  }
  .interpretation-box {
    .el-tabs__nav {
      margin-left: 0;
    }
    .el-tabs__nav-next,
    .el-tabs__nav-prev {
      line-height: 29px !important;
    }
    .el-tabs__nav-prev {
      left: 5px; /*no*/
    }
    .el-tabs__nav-next {
      right: 5px; /*no*/
    }
    .el-input__inner {
      color: @black;
    }
    .el-tabs__header {
      margin-bottom: 8px; /*no*/
    }
    .el-tabs__nav-wrap {
    }
    .el-tabs__item {
      height: 32px !important; /*no*/
    }
  }
}
</style>
